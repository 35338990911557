import React from 'react';
import {Avatar, Typography} from "@mui/material";
import './serviceBlock2.css';
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import * as myConstClass from './Constants';

function ServiceBlock1() {


  return (
      <div style={{
        display: 'flex' }}>
          <div style={{
            backgroundImage: `url("traffic.jpg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height:'60vw',
            width: '45vw',
            backgroundRepeat: 'no-repeat',
          }}>
            <div id="div1"
                 style={{ backgroundColor: '#212121', width: '30vw', paddingTop: 5,
                   display: 'inline-grid', justifyItems: 'center' }}>
              <Avatar sx={{
                bgcolor: '#ffd54f',
                width: '6.5vw',
                height: '6.5vw',
                maxHeight: '40px',
                maxWidth: '40px',
                color: 'black',
                border: '2px solid #ffd54f'
              }}>
                <DirectionsBusFilledIcon/>
              </Avatar>
              <Typography style={{fontSize: '1.8vw', fontWeight: 'bold', color: '#ffd54f',
                textAlign: 'center', margin: 4}}>
                Кропивницький - Київ - Кропивницький
              </Typography>
              <Typography style={{fontSize: '2vw', color: 'white', margin: '2vw 1vw 1vw 1vw'}}>
                Ви можете замовити поїздку від бажаної адреси з
                Кропивницького до зупинки "Станція метро Видубичі" / "Залізничний вокзал" та
                навпаки.
              </Typography>
              <Typography style={{
                width: '100%',
                fontSize: '2vw',
                fontWeight: 'bold',
                color: '#ffd54f',
                margin: '1vw 0vw 1vw 1vw'
              }}>
                { 'Вартість: '+ myConstClass.PRICE }
              </Typography>
            </div>
          </div>
        <div style={{width: '6vw'}}></div>
          <div style={{
            backgroundImage: `url("plane1.jpg")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '45vw',
            height:'60vw',
            backgroundRepeat: 'no-repeat',
          }}>    <div id="div1"
                      style={{ backgroundColor: '#212121', width: '30vw', paddingTop: 5,
                        display: 'inline-grid', justifyItems: 'center' }}>
            <Avatar sx={{
              bgcolor: '#ffd54f',
              width: '6.5vw',
              height: '6.5vw',
              maxHeight: '40px',
              maxWidth: '40px',
              color: 'black',
              border: '2px solid #ffd54f'
            }}>
              <FlightTakeoffIcon/>
            </Avatar>
            <Typography style={{fontSize: '1.8vw', fontWeight: 'bold', color: '#ffd54f',
              textAlign: 'center', margin: 4}}>
              Трансфер до столичних аеропортів
            </Typography>
            <Typography style={{fontSize: '2vw', color: 'white', margin: '2vw 1vw 1vw 1vw'}}>
              Ви можете замовити поїздку від бажаної адреси з Кропивницького до столичних
              аеропортів "Бориспіль" / "Жуляни"
            </Typography>
            <Typography style={{
              width: '100%',
              fontSize: '2vw',
              fontWeight: 'bold',
              fontStyle: 'italic',
              color: '#ffd54f',
              margin: '1vw 0vw 1vw 1vw'
            }}>
              * (тимчасово ця опція неактивна)
            </Typography>
          </div>
          </div>
        </div>
  );
}

export default ServiceBlock1;